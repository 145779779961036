




















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class ButtonCircle extends Vue {
  @Prop({ type: String, default: 'button' })
  tag!: 'button' | 'nuxt-link' | 'div';

  @Prop({ type: String, required: false })
  to!: string;

  @Prop({ type: String, required: true })
  text!: string;

  @Prop({
    type: String,
    default: 'white',
    validator (value) {
      return [
        'pink-100', 'red-100', 'yellow-100', 'dark', 'light', 'white',
      ].includes(value);
    },
  })
  variant!: string;

  @Prop({
    type: String,
    required: false,
    validator (value) { return ['xs', 'sm', 'md', 'lg'].includes(value) },
  })
  size!: string;

  @Prop(Boolean)
  disabled!: boolean;

  get sizeClass () {
    return this.size ? `-${this.size}` : '';
  }

  get isInternalLink () {
    return this.to && this.to.startsWith('/');
  }

  get isExternalLink () {
    return this.to && !this.isInternalLink;
  }

  get component () {
    if (this.isExternalLink)
      return 'a';

    if (this.isInternalLink)
      return 'nuxt-link';

    return this.tag;
  }

  get buttonProps () {
    if (this.isInternalLink)
      return { to: this.to };

    if (this.isExternalLink)
      return {
        href: this.to,
        rel: 'noopener noreferrer',
        target: '_blank',
      };

    if (this.tag === 'button')
      return { type: 'button' };

    return {};
  }
}
